import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

import { Layout } from 'src/components/Layout';
import { ArrowButton } from 'src/components/ArrowButton';
import { TextInput } from 'src/components/TextInput';
import { UserManager } from 'src/helpers/UserManager';
import { navigate } from 'gatsby';
import { showMessage } from 'src/components/Message';
import { Spinner } from 'src/components/Spinner';
import { RegistrationManager } from 'src/helpers/RegistrationManager';

interface Props {}

interface State {
	loading: boolean;
	email: string;
	password: string;
	url?: string;
}

export default class SignIn extends React.Component<Props, State> {
	state: State = { loading: true, email: '', password: '' };

	componentDidMount = async () => {
		if ((await UserManager.getAuthenticatedUser()) && !(await UserManager.isGuestUser())) {
			navigate('/');
			return;
		}
		let urlParams;
		// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
		if (typeof window !== 'undefined') {
			urlParams = new URLSearchParams(window.location.search);
		}
		const url = (urlParams ? urlParams.get('url') : undefined) || '';
		this.setState({ loading: false, url });
	};

	authenticateUser = async () => {
		const { url } = this.state;
		const result = await UserManager.authenticate({
			email: this.state.email,
			password: this.state.password,
		});
		if (result.user) {
			await RegistrationManager.acceptRegistration(result.user.id);
			navigate(`/${url}`);
		} else {
			showMessage({ caption: `Invalid username or password` });
			this.setState({});
		}
	};

	render() {
		const { loading, email, password } = this.state;
		// TODO: better client side validation
		const hasValidResponses = email !== '' && password !== '';

		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? (
						<>
							<h1 className={classNames(styles.heading, styles.headingContainer)}>Sign In</h1>
							<div className={styles.innerLogin}>
								<div className={styles.emailAndPasswordContainer}>
									<div className={styles.padRightContainer}>
										<TextInput
											caption={'Email address'}
											value={email}
											onValueChanged={(textValue) => {
												this.setState({ email: textValue });
											}}
											onEnter={this.authenticateUser}
										/>
									</div>
									<div className={styles.padLeftContainer}>
										<TextInput
											caption={'Password'}
											value={password}
											onValueChanged={(textValue) => {
												this.setState({ password: textValue });
											}}
											isPasswordInput={true}
											onEnter={this.authenticateUser}
										/>
									</div>
								</div>
								<div className={styles.forgotPasswordContainer}>
									<div className={styles.forgotPassword}>
										<button
											className={styles.forgotPasswordLink}
											onClick={() => navigate('/password-reset')}
										>
											Forgot your password?
										</button>
									</div>
								</div>
								<div className={styles.forgotPasswordContainer}>
									<div className={styles.forgotPassword}>
										Don't have an account?&nbsp;
										<button
											className={styles.forgotPasswordLink}
											onClick={() => navigate('/sign-up')}
										>
											Create one
										</button>
									</div>
								</div>
							</div>
							<ArrowButton
								caption={'Sign In'}
								onClick={this.authenticateUser}
								isDisabled={!hasValidResponses}
							/>
						</>
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
